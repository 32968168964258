import React from 'react';
import { withTranslation } from 'react-i18next';

import {Hero, Footer, Features, Pricing, References, Reference, SignupDivider, BookDemo} from '../../components';
import {LandingLayout} from '../../layouts';

const IndexPage = ({t, i18n}) => {
  return (
    <LandingLayout
      title="Duuers"
      language="fi"
      meta={[
        {name: 'robots', content:'noindex, nofollow'}, 
      ]}
    >
      <Hero>
        <h1 className="blue font_second light font102 top45">Luo tarjous malli 60 sekunnissa</h1>
    		<div className="margin_auto top40 max_width770 blue light font22 text">
    			<p>Tarjous malli työkalumme tekee elämästäsi paljon helpompaa!</p>
    		</div>
    		<a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size50 transparent_blue border border_blue radius18 padding_sides35 sides25 top45">{t('menu.signup')}</a>
      </Hero>
      <Features/>
      <SignupDivider/>
      <References>
        <Reference name="Reeta Laamo" company="Red & Blue" image="/images/references/reeta_laamo_500x500.png">
         Duuers nopeuttaa ja helpottaa tarjousten tekemistä. Meillä virheiden määrä on vähentynyt, ja vastaanottajat ovat kiitelleet siistejä tarjouspohjia. Yhteen tarjoukseen unohdin väärän hinnan, mutta pystyin Duuersin avulla muokkaamaan lähettämääni tarjousta jo ennen kuin vastaanottaja oli avannut tarjouksen. Duuers tarjoaa näkymän siihen miten tarjouksia tulevaisuudessa pyöritellään. Suosittelen!
        </Reference>
        <Reference  name="Iiro Hänninen" company="Korttilinna" image="/images/references/iiro_500x500.png">
  			  Duuers oli nopea ja helppo ottaa käyttöön. Laatu ja näkyvyys ovat parantuneet huomattavasti ja myös asiakkaat ovat antaneet pointsit palvelusta! Käytännössä olen siirtänyt koko tarjoustyöskentelyni Duuersiin. Takaisin vanhaan ei ole paluuta!
  			</Reference>
  			<Reference  name="Jani Teräväinen" company="Työmaapalvelut Express" image="/images/references/jani_500x500.png">
  			  Duuersin merkitys kasvaa koko ajan! Tarjoustyöskentely on nopeutunut ja tehostunut huomattavasti ja kannustanut uudistamaan muitakin yrityksen osa-alueita. Olen jälleen askeleen lähempänä paperitonta toimistoa!
  			</Reference>
  			<Reference  name="Heikki Holmström" company="Munkkiniemen Remonttiapu">
  			  Duuers nopeuttaa tarjousten tekoaikaani puolella ja nopeutuu koko ajan. Tarjousten tekemisestä on tullut helppoa, ja erityisesti uuden tarjouksen aloittamisesta on tullut mukavaa!
  			</Reference>
      </References>
      <Pricing/>
      <BookDemo/>
      <Footer/>
    </LandingLayout>
);
};


export default withTranslation()(IndexPage);
